<template>
  <div class="wrapper">
    <div class="page-header page-header-small header-filter skew-separator skew-mini">
      <div class="page-header-image" style=" background-image: url('img/pages/boxed-water.jpg')"></div>
      <div class="container pt-0">
        <div class="row">
          <div class="col-lg-6 col-md-7 mx-auto text-center">
            <h1 class="title text-white"></h1>
            <br />
          </div>
        </div>
      </div>
      <!-- SVG separator -->
      <div class="separator separator-bottom separator-skew">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <div class="main">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <router-link to="/product-page">
              <card class="card-blog">
                <template slot="image">
                  <img class="card-img-top" src="img/pages/cityheaven/01/p_02.jpg" alt="Image placeholder" />
                </template>
                <template slot="body">
                  <h6 class="card-category text-danger">
                    <i class="ni ni-badge"></i> 新川 ちえ
                  </h6>
                  <h5 class="card-title">
                    T165・94(G cup)・59・90 24歳
                  </h5>
                  <p class="card-description">
                    『リアル・ボンドガール』爆誕
                  </p>
                </template>
              </card>
            </router-link>
          </div>
          <div class="col-lg-4 col-md-6">
            <router-link to="/product-page">
              <card class="card-blog">
                <template slot="image">
                  <img class="card-img-top" src="img/pages/cityheaven/02/p_02.jpg" alt="Image placeholder" />
                </template>
                <template slot="body">
                  <h6 class="card-category text-danger">
                    <i class="ni ni-badge"></i> 毛利 つむぎ
                  </h6>
                  <h5 class="card-title">
                    T167・87(D cup)・56・85 血液型:A型27歳
                  </h5>
                  <p class="card-description">
                    極上のスタイル
                  </p>
                </template>
              </card>
            </router-link>
          </div>
          <div class="col-lg-4 col-md-6">
            <router-link to="/product-page">
              <card class="card-blog">
                <template slot="image">
                  <img class="card-img-top" src="img/pages/cityheaven/03/p_02.jpg" alt="Image placeholder" />
                </template>
                <template slot="body">
                  <h6 class="card-category text-danger">
                    <i class="ni ni-badge"></i> 吉岡 かんな
                  </h6>
                  <h5 class="card-title">
                    T160・83(C cup)・56・84 25歳
                  </h5>
                  <p class="card-description">
                    THE王道清楚系素人娘!!
                  </p>
                </template>
              </card>
            </router-link>
          </div>
          <div class="col-lg-4 col-md-6">
            <router-link to="/product-page">
              <card class="card-blog">
                <template slot="image">
                  <img class="card-img-top" src="img/pages/cityheaven/04/p_02.jpg" alt="Image placeholder" />
                </template>
                <template slot="body">
                  <h6 class="card-category text-danger">
                    <i class="ni ni-badge"></i> 夢咲 ぴぃ
                  </h6>
                  <h5 class="card-title">
                    T165・101(H cup)・60・89 27歳
                  </h5>
                  <p class="card-description">
                    爆乳都市伝説!!
                  </p>
                </template>
              </card>
            </router-link>
          </div>
          <div class="col-lg-4 col-md-6">
            <router-link to="/product-page">
              <card class="card-blog">
                <template slot="image">
                  <img class="card-img-top" src="img/pages/cityheaven/05/p_02.jpg" alt="Image placeholder" />
                </template>
                <template slot="body">
                  <h6 class="card-category text-danger">
                    <i class="ni ni-badge"></i> 中山 みう
                  </h6>
                  <h5 class="card-title">
                    T150・84(D cup)・58・86 34歳
                  </h5>
                  <p class="card-description">
                    ミニマムの癒し系
                  </p>
                </template>
              </card>
            </router-link>
          </div>
        </div>
      </div>
      <div class="testimonials-1" style="background-image: url('img/ill/testimonial_bg.svg')">
        <div class="row ">
          <div class="col-md-6 ml-auto mr-auto text-center">
            <h2 class="title">你可能會喜歡</h2>
          </div>
        </div>
        <b-carousel id="carousel-testimonials" height="150vh" class="carousel-team" ref="carouselTestimonials"
          style="margin-top: 0;">
          <b-carousel-slide class="carousel-inner">
            <div class="row text-left">
              <div class="col-md-5 p-5 ml-auto">
                <div class="p-3">
                  <img class="img-fluid rounded shadow transform-perspective-right" src="img/pages/cityheaven/02/l_02.jpg"
                    alt="First slide" />
                </div>
              </div>
            </div>
          </b-carousel-slide>
          <b-carousel-slide class="carousel-inner">
            <div class="row text-left">
              <div class="col-md-5 p-5 ml-auto">
                <div class="p-3">
                  <img class="img-fluid rounded shadow transform-perspective-right" src="img/pages/cityheaven/03/l_03.jpg"
                    alt="First slide" />
                </div>
              </div>
            </div>
          </b-carousel-slide>
          <b-carousel-slide class="carousel-inner">
            <div class="row text-left">
              <div class="col-md-5 p-5 ml-auto">
                <div class="p-3">
                  <img class="img-fluid rounded shadow transform-perspective-right" src="img/pages/cityheaven/04/l_02.jpg"
                    alt="First slide" />
                </div>
              </div>
            </div>
          </b-carousel-slide>
          <div class="row text-center">
            <a class="carousel-control-prev text-primary" v-on:click="prev" role="button" data-slide="prev">
              <i class="ni ni-bold-left"></i>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next text-primary" v-on:click="next" role="button" data-slide="next"
              style="margin-left: 60px;">
              <i class="ni ni-bold-right"></i>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </b-carousel>
      </div>
    </div>
  </div>
</template>
<script>
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
import "bootstrap-vue/dist/bootstrap-vue.min.css";
import { BCarousel } from "bootstrap-vue/esm/components/carousel/carousel";
import { BCarouselSlide } from "bootstrap-vue/esm/components/carousel/carousel-slide";

export default {
  bodyClass: "ecommerce-page",
  components: {
    BCarousel,
    BCarouselSlide,
  },
  directives: {
    BTooltip: VBTooltip,
  },
  methods: {
    prev() {
      this.$refs.carouselTestimonials.prev();
    },
    next() {
      this.$refs.carouselTestimonials.next();
    },
  },
};
</script>
<style>
.testimonials-1 .carousel-caption {
  width: 100%;
  left: 0 !important;
}
</style>
