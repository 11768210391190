<template>
  <div class="team-3">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto text-center mb-5">
          <h3 class="display-3">You are into a great company</h3>
          <p class="lead">
            This is the paragraph where you can write more details about your
            team. Keep you user engaged by providing meaningful information.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-5 mx-auto">
          <card class="card-profile" data-background="full">
            <a href="javascript:;">
              <img class="img img-raised rounded" src="img/theme/lucy.jpg" />
            </a>
            <template slot="image">
              <base-dropdown menuClasses="dropdown-menu-right">
                <div
                  slot="title"
                  class="nav-link dropdown-toggle text-white"
                  data-toggle="dropdown"
                >
                  <i class="ni ni-settings-gear-65"></i>
                </div>
                <a class="dropdown-item" href="javascript:;">Edit Profile</a>
                <a class="dropdown-item" href="javascript:;">Settings</a>
                <a class="dropdown-item" href="javascript:;">Log out</a>
              </base-dropdown>
            </template>
            <template slot="body">
              <h4 class="display-4">Jane Doe</h4>
              <p class="lead mt-0 mb-1">Team Lead</p>
              <div class="table-responsive">
                <table class="table tablesorter" id="plain-table">
                  <tbody>
                    <tr>
                      <td class="text-left pl-0">
                        <div class="badge badge-circle badge-info mr-2">
                          <i class="ni ni-hat-3"></i>
                        </div>
                        Skills
                      </td>
                      <td class="text-right">Leadership</td>
                    </tr>
                    <tr>
                      <td class="text-left pl-0">
                        <div class="badge badge-circle badge-info mr-2">
                          <i class="ni ni-satisfied"></i>
                        </div>
                        Hobbies
                      </td>
                      <td class="text-right">Skiing, Chess</td>
                    </tr>
                    <tr>
                      <td class="text-left pl-0">
                        <div class="badge badge-circle badge-info mr-2">
                          <i class="ni ni-bullet-list-67"></i>
                        </div>
                        Level
                      </td>
                      <td class="text-right">• • • • •</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </card>
        </div>
        <div class="col-lg-4 col-md-5 mx-auto">
          <card class="card-profile" data-background="full">
            <a href="javascript:;">
              <img class="img img-raised rounded" src="img/theme/charlie.jpg" />
            </a>
            <template slot="image">
              <base-dropdown menuClasses="dropdown-menu-right">
                <div
                  slot="title"
                  class="nav-link dropdown-toggle text-white"
                  data-toggle="dropdown"
                >
                  <i class="ni ni-settings-gear-65"></i>
                </div>
                <a class="dropdown-item" href="javascript:;">Edit Profile</a>
                <a class="dropdown-item" href="javascript:;">Settings</a>
                <a class="dropdown-item" href="javascript:;">Log out</a>
              </base-dropdown>
            </template>
            <template slot="body">
              <h4 class="display-4">Hannah Klein</h4>
              <p class="lead mt-0 mb-1">CTO</p>
              <div class="table-responsive">
                <table class="table tablesorter" id="plain-table">
                  <tbody>
                    <tr>
                      <td class="text-left pl-0">
                        <div class="badge badge-circle badge-info mr-2">
                          <i class="ni ni-hat-3"></i>
                        </div>
                        Skills
                      </td>
                      <td class="text-right">Leadership</td>
                    </tr>
                    <tr>
                      <td class="text-left pl-0">
                        <div class="badge badge-circle badge-info mr-2">
                          <i class="ni ni-satisfied"></i>
                        </div>
                        Hobbies
                      </td>
                      <td class="text-right">Skiing, Chess</td>
                    </tr>
                    <tr>
                      <td class="text-left pl-0">
                        <div class="badge badge-circle badge-info mr-2">
                          <i class="ni ni-bullet-list-67"></i>
                        </div>
                        Level
                      </td>
                      <td class="text-right">• • • • •</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </card>
        </div>
        <div class="col-lg-4 col-md-5 mx-auto">
          <card class="card-profile" data-background="full">
            <a href="javascript:;">
              <img
                class="img img-raised rounded"
                src="img/theme/willy-dade.jpg"
              />
            </a>
            <template slot="image">
              <base-dropdown menuClasses="dropdown-menu-right">
                <div
                  slot="title"
                  class="nav-link dropdown-toggle text-white"
                  data-toggle="dropdown"
                >
                  <i class="ni ni-settings-gear-65"></i>
                </div>
                <a class="dropdown-item" href="javascript:;">Edit Profile</a>
                <a class="dropdown-item" href="javascript:;">Settings</a>
                <a class="dropdown-item" href="javascript:;">Log out</a>
              </base-dropdown>
            </template>
            <template slot="body">
              <h4 class="display-4">Lucy Khan</h4>
              <p class="lead mt-0 mb-1">Brand Strategist</p>
              <div class="table-responsive">
                <table class="table tablesorter" id="plain-table">
                  <tbody>
                    <tr>
                      <td class="text-left pl-0">
                        <div class="badge badge-circle badge-info mr-2">
                          <i class="ni ni-hat-3"></i>
                        </div>
                        Skills
                      </td>
                      <td class="text-right">Leadership</td>
                    </tr>
                    <tr>
                      <td class="text-left pl-0">
                        <div class="badge badge-circle badge-info mr-2">
                          <i class="ni ni-satisfied"></i>
                        </div>
                        Hobbies
                      </td>
                      <td class="text-right">Skiing, Chess</td>
                    </tr>
                    <tr>
                      <td class="text-left pl-0">
                        <div class="badge badge-circle badge-info mr-2">
                          <i class="ni ni-bullet-list-67"></i>
                        </div>
                        Level
                      </td>
                      <td class="text-right">• • • • •</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseDropdown from "@/components/BaseDropdown";

export default {
  components: {
    BaseDropdown,
  },
};
</script>
<style></style>
