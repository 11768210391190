<template>
  <div class="wrapper">
    <div class="page-header header-filter page-header-small skew-separator skew-mini">
      <div class="page-header-image" style=" background-image: url('img/pages/boxed-water.jpg')"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-7 mx-auto text-center">
            <h1 class="title text-white"></h1>
            <br />
            <!-- <h4 class="category text-white opacity-8">
              This is the best way to find your favorite stuff
            </h4> -->
          </div>
        </div>
      </div>
      <!-- SVG separator -->
      <div class="separator separator-bottom separator-skew">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <div class="section section-item" style="padding-top:0">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <b-carousel id="carousel1" ref="carousel1">
              <!-- Text slides with image -->
              <b-carousel-slide img-src="img/pages/cityheaven/01/p_08.jpg">
              </b-carousel-slide>
              <b-carousel-slide img-src="img/pages/cityheaven/01/p_03.jpg">
              </b-carousel-slide>
              <b-carousel-slide img-src="img/pages/cityheaven/01/p_07.jpg">
              </b-carousel-slide>
              <a class="carousel-control-prev" v-on:click="prev" role="button" data-slide="prev">
                <button type="button" class="btn btn-round btn-md" name="button">
                  <i class="ni ni-bold-left"></i>
                </button>
              </a>

              <a class="carousel-control-next" v-on:click="next" role="button" data-slide="next">
                <button type="button" class="btn btn-round btn-md" name="button">
                  <i class="ni ni-bold-right"></i>
                </button>
              </a>
            </b-carousel>
          </div>
          <div class="col-lg-6 col-md-12 mx-auto">
            <h2 class="title">遠藤さな</h2>
            <div class="stats">
              <div class="stars text-warning">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <p class="d-inline">(76人評論)</p>
              </div>
            </div>
            <br />
            <h5 class="main-price">T155・88(D cup)・59・87 26歳</h5>
            <h5 class="main-price">服務</h5>
            <h5 class="main-price">
              2400/40/1 (會員價) <br>
              2800/60/1 (會員價) <br>
              3400/60/2 (會員價) <br>
              4400/80/2 (多節價) <br>
              4900/100/2 (多節價) <br>
              5400/120/2 (多節價)
            </h5>
            <br />
            <div class="row pick-size">
              <div class="col-lg-4 col-md-4 col-sm-6">
                <label>請選擇方案</label>
                <choices-single :options="options1" v-model="selected" @input="doInput">
                  <option disabled value="">Select one</option>
                </choices-single>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 mt-2">
                <label>請選擇日期</label>
                <base-input addon-left-icon="ni ni-calendar-grid-58">
                  <flat-picker slot-scope="{ focus, blur }" @on-open="focus" @on-close="blur"
                    @on-change="doSomethingOnChange" :config="{ allowInput: true }" class="form-control datepicker"
                    v-model="dates.datetime">
                  </flat-picker>
                </base-input>
              </div>
              <div v-if="isShow">
                <div class="col-lg-4 col-md-4 col-sm-6 mt-2">
                  <label>請選擇時間</label>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 mt-2">
                  <base-button class="mt-2" type="default" outline>18:00 </base-button>
                  <base-button class="mt-2" type="default" outline>19:00</base-button>
                  <base-button class="mt-2" :class="{ disabled: isActive }" type="default" outline>20:00</base-button>
                  <base-button class="mt-2" type="default" outline>21:00</base-button>
                  <base-button class="mt-2" type="default" outline>22:00</base-button>
                  <base-button class="mt-2" :class="{ disabled: isActive }" type="default" outline>23:00</base-button>
                </div>
              </div>
            </div>
            <br />
            <div class="col-sm-4 col-12 pl-lg-0">
              <base-button type="warning" class="btn-block" @click="onclick()">
                預約 &nbsp;<i class="ni ni-cart"></i>
              </base-button>
            </div>
            <!-- <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block"
                    >More</base-button
                  >
                </div> -->
          </div>
        </div>
      </div>
    </div>
    <br />

    <!-- Testimonial Section -->
    <Testimonial></Testimonial>
    <!-- <div class="section related-products bg-secondary skew-separator skew-top mt-7">
      <div class="container">
        <div class="col-md-8">
          <h2 class="title">You may also like</h2>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <card class="card-product">
              <template slot="image">
                <a href="#pablo">
                  <img class="img rounded" src="img/pages/tshirt.png" />
                </a>
              </template>
              <template slot="body">
                <h6 class="category text-warning">Trending</h6>
                <h4 class="card-title">
                  <a href="javascript:;" class="card-link">Gucci</a>
                </h4>
                <div class="card-description opacity-8 mt-2">
                  Dolce &amp; Gabbana's 'Greta' tote has been crafted in Italy
                  from hard-wearing red textured-leather.
                </div>
                <div class="card-footer">
                  <div class="price-container">
                    <span class="price">€1,459</span>
                  </div>
                  <base-button type="warning" iconOnly size="sm" class="btn-round pull-right">
                    <i class="ni ni-favourite-28"></i>
                  </base-button>
                </div>
              </template>
            </card>
          </div>
          <div class="col-lg-3 col-md-6">
            <card class="card-product">
              <template slot="image">
                <a href="#pablo">
                  <img class="img rounded" src="img/pages/shorts.png" />
                </a>
              </template>
              <template slot="body">
                <h6 class="category text-warning">Popular</h6>
                <h4 class="card-title">
                  <a href="javascript:;" class="card-link">Balmain</a>
                </h4>
                <div class="card-description opacity-8 mt-2">
                  Balmain's mid-rise skinny jeans are cut with stretch to ensure
                  they retain their second-skin fit but move comfortably.
                </div>
                <div class="card-footer">
                  <div class="price-container">
                    <span class="price">€459</span>
                  </div>
                  <base-button type="warning" iconOnly size="sm" class="btn-round pull-right">
                    <i class="ni ni-favourite-28"></i>
                  </base-button>
                </div>
              </template>
            </card>
          </div>
          <div class="col-lg-3 col-md-6">
            <card class="card-product">
              <template slot="image">
                <a href="#pablo">
                  <img class="img rounded" src="img/pages/shirt.png" />
                </a>
              </template>
              <template slot="body">
                <h6 class="category text-warning">Popular</h6>
                <h4 class="card-title">
                  <a href="javascript:;" class="card-link">Balenciaga</a>
                </h4>
                <div class="card-description opacity-8 mt-2">
                  Balenciaga's black textured-leather wallet is finished with
                  the label's iconic 'Giant' studs. This is where you can...
                </div>
                <div class="card-footer">
                  <div class="price-container">
                    <span class="price">€559</span>
                  </div>
                  <base-button type="warning" iconOnly size="sm" class="btn-round pull-right">
                    <i class="ni ni-favourite-28"></i>
                  </base-button>
                </div>
              </template>
            </card>
          </div>
          <div class="col-lg-3 col-md-6">
            <card class="card-product">
              <template slot="image">
                <a href="#pablo">
                  <img class="img rounded" src="img/pages/jeans.png" />
                </a>
              </template>
              <template slot="body">
                <h6 class="category text-warning">Trending</h6>
                <h4 class="card-title">
                  <a href="javascript:;" class="card-link">D &amp; G</a>
                </h4>
                <div class="card-description opacity-8 mt-2">
                  Dolce &amp; Gabbana's 'Greta' tote has been crafted in Italy
                  from hard-wearing red textured-leather.
                </div>
                <div class="card-footer">
                  <div class="price-container">
                    <span class="price">€1,359</span>
                  </div>
                  <base-button type="warning" iconOnly size="sm" class="btn-round pull-right">
                    <i class="ni ni-favourite-28"></i>
                  </base-button>
                </div>
              </template>
            </card>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { BCarousel } from "bootstrap-vue/esm/components/carousel/carousel";
import { BCarouselSlide } from "bootstrap-vue/esm/components/carousel/carousel-slide";
import Testimonial from "../sections/Testimonial/Testimonial3.vue";
import ChoicesSingle from "@/components/SingleSelect";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  bodyClass: "product-page",
  components: {
    BCarousel,
    BCarouselSlide,
    Testimonial,
    ChoicesSingle,
    flatPicker
  },
  data() {
    return {
      isShow: false,
      dates: {
        datetime: ""
      },
      isActive: false,
      quantity: 1,
      selected: 2,
      options1: [
        { id: 1, text: "2400/40/1 (會員價)" },
        { id: 2, text: "2800/60/1 (會員價)" },
        { id: 3, text: "3400/60/2 (會員價)" },
        { id: 4, text: "4400/80/2 (多節價)" },
        { id: 5, text: "4900/100/2 (多節價)" },
        { id: 6, text: "5400/120/2 (多節價)" },
      ],
      options2: [
        { id: 1, text: "S" },
        { id: 2, text: "M" },
        { id: 3, text: "L" },
        { id: 4, text: "XS" },
        { id: 5, text: "XL" },
      ],
    };
  },
  methods: {
    prev() {
      this.$refs.carousel1.prev();
    },
    next() {
      this.$refs.carousel1.next();
    },
    increaseQuantity() {
      this.quantity++;
    },
    decreaseQuantity() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },
    doSomethingOnChange() {
      if (this.datesend != "") {
        this.isShow = true
      }
    },
    doInput() {
      if (this.selected != "") {
        this.isActive = true
      }
    }
  },
};
</script>
<style>
.product-page .carousel-item {
  height: 60vh;
}

.product-page .carousel a.carousel-control-next {
  right: -20px;
}

.product-page .carousel a.carousel-control-prev {
  left: -20px;
}
</style>
