<template>
  <div class="testimonials-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 mx-auto text-left">
          <h3 class="display-3">美女介紹</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-8 col-10 positioned">
          <h4 class="main-price">最辣的美尻老師 曼妙美體 讓人熱氣衝腦</h4>
          <p class="main-price">
            *蒂蒂* <br>
            160/47/E/23Y <br>
            舌吻 品鮑 奶炮 69 舔蛋 <br>
            戴套做 無套吹 全身按摩 甜蜜共浴 <br>
            可配合 絲襪 高跟鞋 性感睡衣 <br>
            買2送1(180/3) 買3送1(240/3)
          </p>
          <!-- <base-button tag="a" href="javascript:;" type="primary" class="mt-5">Contact us</base-button> -->
        </div>
        <div class="col-md-12 col-md-6 " style="margin-top: 60px;">
          <div class="testimonials-1" style="background-image: url('img/ill/testimonial_bg.svg')">
            <div class="row ">
              <div class="col-md-6 ml-auto mr-auto text-center">
                <h2 class="title">你可能會喜歡</h2>
              </div>
            </div>
            <b-carousel id="carousel-testimonials" height="150vh" class="carousel-team" ref="carouselTestimonials"
              style="margin-top: 0;">
              <b-carousel-slide class="carousel-inner">
                <div class="row text-left">
                  <div class="col-md-5 p-5 ml-auto">
                    <div class="p-3">
                      <img class="img-fluid rounded shadow transform-perspective-right"
                        src="img/pages/cityheaven/02/l_02.jpg" alt="First slide" />
                    </div>
                  </div>
                </div>
              </b-carousel-slide>
              <b-carousel-slide class="carousel-inner">
                <div class="row text-left">
                  <div class="col-md-5 p-5 ml-auto">
                    <div class="p-3">
                      <img class="img-fluid rounded shadow transform-perspective-right"
                        src="img/pages/cityheaven/03/l_03.jpg" alt="First slide" />
                    </div>
                  </div>
                </div>
              </b-carousel-slide>
              <b-carousel-slide class="carousel-inner">
                <div class="row text-left">
                  <div class="col-md-5 p-5 ml-auto">
                    <div class="p-3">
                      <img class="img-fluid rounded shadow transform-perspective-right"
                        src="img/pages/cityheaven/04/l_02.jpg" alt="First slide" />
                    </div>
                  </div>
                </div>
              </b-carousel-slide>
              <div class="row text-center">
                <a class="carousel-control-prev text-primary" v-on:click="prev" role="button" data-slide="prev">
                  <i class="ni ni-bold-left"></i>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next text-primary" v-on:click="next" role="button" data-slide="next"
                  style="margin-left: 60px;">
                  <i class="ni ni-bold-right"></i>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </b-carousel>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>

import { BCarousel } from "bootstrap-vue/esm/components/carousel/carousel";
import { BCarouselSlide } from "bootstrap-vue/esm/components/carousel/carousel-slide";


export default {
  components: {
    BCarousel,
    BCarouselSlide,
  },
  methods: {
    prev() {
      this.$refs.carouselTestimonials.prev();
    },
    next() {
      this.$refs.carouselTestimonials.next();
    },
  },
};
</script>
<style>
.testimonials-1 .carousel-caption {
  width: 100%;
  left: 0 !important;
}
</style>