<template>
  <div class="cd-section" id="features">
    <!--     *********     FEATURE 1      *********      -->
    <Feature1></Feature1>
    <!--     *********     FEATURE 2      *********      -->
    <Feature2></Feature2>

    <!--     *********     FEATURE 3      *********      -->
    <Feature3></Feature3>

    <!--     *********     FEATURE 4      *********      -->
    <Feature4></Feature4>

    <!--     *********     FEATURE 5      *********      -->
    <Feature5></Feature5>

    <!--     *********     FEATURE 6      *********      -->
    <Feature6></Feature6>

    <!--     *********     FEATURE 7      *********      -->
    <Feature7></Feature7>
  </div>
</template>
<script>
import Feature1 from "./Features/Feature1.vue";
import Feature2 from "./Features/Feature2.vue";
import Feature3 from "./Features/Feature3.vue";
import Feature4 from "./Features/Feature4.vue";
import Feature5 from "./Features/Feature5.vue";
import Feature6 from "./Features/Feature6.vue";
import Feature7 from "./Features/Feature7.vue";

export default {
  components: {
    Feature1,
    Feature2,
    Feature3,
    Feature4,
    Feature5,
    Feature6,
    Feature7,
  },
};
</script>
<style></style>
